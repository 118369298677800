import styled, { keyframes } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';


export const Container = styled.div`
background: pink;
width: 100%;
top: 5rem;
height: 100%;
position: absolute;
display: grid;
grid-template-columns: repeat(12, 1fr);
grid-auto-rows: minmax(500px, auto);
grid-gap: 10px;
justify-content: center;

${MEDIA.TABLET`

  `};
  ${MEDIA.PHONE`
  display: block;
  right: 0rem;

  `};

`

const marqueeAnimation = keyframes`
 0% { 
     transform: translate3d(0, var(--move-initial), 0);
    }
 100% { 
     transform: translate3d(0, var(--move-final), 0);
    }
`

export const MarqueeInner = styled.div`
width: fit-content;
position: relative;
transform: translate3d(0, var(--move-initial), 0);
animation: ${marqueeAnimation} ${props => (props.direction === 'up' ?
    '50s linear infinite' : '25s linear infinite')};
animation-play-state: paused;
`



export const Track1 = styled.div`
grid-column: 1/5;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
box-orient: vertical;
background: yellow;
overflow: hidden;
--offset: -260rem;
--move-initial: calc(130rem + var(--offset));
--move-final: calc(390rem + var(--offset));
align-items:center;
&:hover {
  ${MarqueeInner} {
      // background: red;
      div {
      animation-play-state: paused;
    }
  }
} 
${MEDIA.PHONE`
display: none;

`};

`

export const Track2 = styled.div`
grid-column: 5/9;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
box-orient: vertical;
background: black;
overflow: hidden;
--offset: -260rem;
--move-initial: calc(390rem + var(--offset));
--move-final: calc(130rem + var(--offset));
align-items:center;
&:hover {
  ${MarqueeInner} {
      // background: red;
      div {
      animation-play-state: paused;
    }
  }
} 
`
export const Track3 = styled.div`
grid-column: 9/13;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
box-orient: vertical;
background: yellow;
overflow: hidden;
--offset: 25vh;
--move-initial: calc(-100% + var(--offset));
--move-final: calc(100% + var(--offset));
align-items:center;
&:hover {
  ${MarqueeInner} {
      // background: red;
      div {
      animation-play-state: paused;
    }
  }
} 
`

export const MarqueeV1 = styled.div`
position: static;
overflow: hidden;
height: 100%;

// background: rgba(0,0,0,0.4);
--offset: 20vh;
--move-initial: calc(-25vh + var(--offset));
--move-final: calc(-75vh + var(--offset));
&:hover {
    ${MarqueeInner} {
        // background: red;
        animation-play-state: paused;
    }
} 
span {
    font-size: 8vw;
    padding: 0 2vw;
    float: left;
}
`



