import styled, { keyframes } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const fadeIn = keyframes`
0% { 
    opacity: 0; 
}
100% { 
    opacity: 1 
}
`

export const HomeTheaterIFrame = styled.iframe`
animation: ${fadeIn} 5s ease forwards;
position: absolute;
  top: -35rem;
  left: 0;
  width: 100%;
  height: 100%;
  // min-height: 80rem;
  min-width: 1300px;
  border: 0;


  ${MEDIA.L_TABLET`
min-height: 80rem;
min-width: 1300px;
top: -20rem;
left: -15rem;
  `};

${MEDIA.TABLET`
top: -14.5rem;

  `};


  ${MEDIA.PHONE`
display: none;

  `};

`

export const HomeTheaterIFrameMobile = styled.iframe`
animation: ${fadeIn} 5s ease forwards;
display: none;
z-index: 1;

${MEDIA.TABLET`
  
  `};

${MEDIA.PHONE`
  display: block;
  // overflow: hidden;
  min-width: 100%;
  min-height: 100%;
  // left: -12.5rem;
  width: 80%;
  height: auto;
  margin-top: -10rem;
  position: absolute;
  top: 0;
  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`
margin-top: -12.5rem;
  `};
`


