import styled, { keyframes } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"


export const CardContainer = styled.li`
height: 50rem;
width:${props => (props.size === 'article' ? '45rem'
        : props.size === 'videoH' ? '55rem'
            : props.size === 'videoV' ? '31rem' : '50rem')};

list-style: none;
background: ${props => (props.size === 'article' ? 'red'
        : props.size === 'videoH' ? `${orange}`
            : props.size === 'videoV' ? 'green' : 'black')};
position: relative;
margin: 0 auto;
margin-bottom: 15rem;
// top: 5.5rem;
z-index: 1;
${MEDIA.TABLET`
// top: 1rem;
  `};
  ${MEDIA.PHONE`
width: 31rem;
height: 60rem;

  `};
`

// Tag
export const Tag = styled.h2`
width: 25%;
height: auto;
background: rgba(0,0,0,0.33);
color: white;
display: flex;
justify-content: center;
align-items: center;
&::before{
    font-size: 1.75rem;
    letter-spacing: .2em;
    font-weight: 600;
    content: ${props => (`"${props.tag}"`)}
}`;

// Title

export const Title = styled.h2`
width: auto;
bottom: 9rem;
position: absolute;
height: 4rem;
display: flex;
padding-left: .75rem;
padding-right: .75rem;
align-items: center;
justify-content: flex-start;
background: white;
border-top: .5rem solid rgb(0,0,0,0.05);
border-left: .75rem solid rgb(0,0,0,0.05);
border-right: .75rem solid rgb(0,0,0,0.05);
border-bottom: .9rem solid rgb(0,0,0,0.1);
border-radius: .5rem;
color: grey;
&::before{
    font-size: 2rem;
    white-space: pre-line;
    word-spacing: .1em;
    font-weight: 600;
    content: ${props => (`"${props.title}"`)}
}
`;

export const Date = styled.p`
color: white;
background: black;
width: 25%;
display: flex;
justify-content: flex-end;
height: auto;
&::before{
    font-size: 1.5rem;
    letter-spacing: .2em;
    font-weight: 800;
    content: ${props => (`"${props.date}"`)}
}
`



// Marquee
const marqueeAnimation = keyframes`
 0% { 
     transform: translate3d(var(--move-initial), 0, 0);
    }
 100% { 
     transform: translate3d(var(--move-final), 0, 0);
    }
`

export const MarqueeInner = styled.div`
aria-hidden: true;
bottom: 0;
background: white;
width: fit-content;
display: flex;
position: absolute;
transform: translate3d(var(--move-initial), 0, 0);
animation: ${marqueeAnimation} 30s linear infinite;
animation-play-state: running;
`

export const Marquee = styled.div`
position: relative;
overflow: hidden;
height: 100%;
background: green;
--offset: 20vw;
--move-initial: calc(-25% + var(--offset));
--move-final: calc(-75% + var(--offset));
&:hover {
    ${MarqueeInner} {
        background: red;
        div {
            animation-play-state: paused;
        }
        
    }
} 
span {
    font-size: 4vw;
    padding: 0 2vw;
}
`


export const MarqueeTitle = styled.h2`
background: white;
// line-height: 7rem;
display: flex;
justify-content: left;
&::before {
    font-weight: 600;
    font-size: 5rem;
    color: blue
    white-space: nowrap
    content: ${props => (`"${props.title}"`)}
}
`

export const MarqueeSummary = styled.p`
background: white;
padding-right: 5rem;
// line-height: 7rem;
display: flex;
align-items: center;
&::before {
    font-size: 2rem;
    word-spacing: 1rem;
    white-space: nowrap;
    content: ${props => (`"${props.summary}"`)}
}
`
export const MarqueePreview = styled.span`
background: white;
position: absolute;
display: flex;
// line-height: 7rem;
&::before {

    font-size: 5rem;
    white-space: nowrap
    content: ${props => (`"${props.preview}"`)}
}
`
export const MarqueeDate = styled.span`
background: white;
// line-height: 7rem;
&::before {
    font-size: 5rem;
    // white-space: nowrap
    content: ${props => (`"${props.date}"`)}
}
`
export const MarqueeBars = styled.hr`
background: ${props => props.bottom ? 'lightgrey' : 'lightgrey'};
position: absolute;
border-top: 1px double black;
bottom: ${props => props.bottom ? '0' : '6rem'};
height:  ${props => props.bottom ? '.25rem' : '.25rem'}
margin: 0 auto;
width: 100%;
z-index: 5;
`
