import React from 'react';
import PropTypes from 'prop-types';
import {
    CardContainer, Tag, Title, Date, Marquee,
    MarqueeInner, MarqueeSummary, MarqueeTitle,
    MarqueeDate, MarqueePreview, MarqueeBars
} from './card.css';

const Card = ({ children, as = '', size, title, summary, preview, date, tags }) => {
    return (
        <CardContainer size={size}>
            <Marquee>
                <Tag tag='tech' />
                <Title title='' />
                <Date date='2.1.21' />
                {children}
                <MarqueeBars />
                <MarqueeInner aria-hidden='true'>
                    <MarqueeSummary summary='The last thing that anyone should be doing is worrying about bills. ' />
                    <MarqueeSummary summary='Here is the summary testing just how much we can get here. ' />
                    <MarqueeSummary summary='Here is the summary testing just how much we can get here. ' />
                    <MarqueeSummary summary='Here is the summary testing just how much we can get here. ' />
                </MarqueeInner>
                <MarqueeBars bottom />
            </Marquee>
        </CardContainer >
    );
};

Card.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default Card;