import React from 'react';
import PropTypes from 'prop-types';
import { Container, MarqueeV1, MarqueeInner, Track1, Track2, Track3 } from './homeMarquee.css';
import Card from '../card/card'
const HomeMarquee = ({ children, vertical, horizontal, words }) => {
  return (
    <Container vertical={vertical} horizontal={horizontal}>
      <Track1>
        <MarqueeInner>
          <Card spacer size='article'>
          </Card>
        </MarqueeInner>
        <MarqueeInner>
          <Card words={words} size='article'>
            1
          </Card>
        </MarqueeInner>
        <MarqueeInner>
          <Card words={words} size='article'>
            2
          </Card>
        </MarqueeInner>
        <MarqueeInner>
          <Card words={words} size='videoH'>
            3
          </Card>
        </MarqueeInner>
        <MarqueeInner>
          <Card words={words} size='article'>
            4
          </Card>
        </MarqueeInner>
        <MarqueeInner>
          <Card words={words} size='article'>
            1
          </Card>
        </MarqueeInner>
      </Track1>
      <Track2>
        <MarqueeInner direction='up'>
          <Card spacer size='article'>
          </Card>
        </MarqueeInner>
        <MarqueeInner direction='up'>
          <Card words={words} size='article'>
            1
          </Card>
        </MarqueeInner>
        <MarqueeInner direction='up'>
          <Card words={words} size='article'>
            2
          </Card>
        </MarqueeInner>
        <MarqueeInner direction='up'>
          <Card words={words} size='videoH'>
            3
          </Card>
        </MarqueeInner>
        <MarqueeInner direction='up'>
          <Card words={words} size='article'>
            4
          </Card>
        </MarqueeInner>
        <MarqueeInner direction='up'>
          <Card words={words} size='article'>
            1
          </Card>
        </MarqueeInner>
      </Track2>
    </Container>

  )
};

HomeMarquee.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeMarquee;
