import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CTAStatement from '../components/Statement/ctaStatement'
import { graphql } from 'gatsby';
import Section from '../components/Section/section'
import { MainSectionContainer } from '../components/Section/section.css'
import FeatureStatement from "../components/Statement/featureStatement"
import Card from '../components/card/card'
import HomeMarquee from '../components/Marquee/homeMarquee'
import { HomeTheaterIFrame } from '../components/Theater/HomeTheater/HomeTheater.css'
import CategoryPageStatement from '../components/Statement/categoryStatement'

const CategoryPage = ({ data }) => (
  <Layout>
    <CategoryPageStatement />
    <Section heading='CategoryPage Name' size=''>
      <MainSectionContainer>
      </MainSectionContainer>

    </Section>
    <Section heading='Heading2' size='medium'>
      <FeatureStatement />
    </Section>
    <Section bg='softblue' heading='Heading3' size=''>
      <HomeMarquee vertical='up' />
    </Section>
    {/* <Section id='carousel' heading='Heading4' size='medium'>

      <CTAStatement />
    </Section> */}
    <Section id='cta/about/contact' heading='Heading 5' size='medium'>
    </Section>

  </Layout>
);

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CategoryPage;

// export const query = graphql`
//   query staticQuery {
//     homeJson {
//       title
//       content {
//         childMarkdownRemark {
//           html
//           rawMarkdownBody
//         }
//       }
//       gallery {
//         title
//         copy
//         image {
//           childImageSharp {
//             fluid(maxHeight: 500, quality: 90) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//   }
// `;
